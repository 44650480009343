import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <header className="bg-panelBackground text-white fixed top-0 w-full shadow-md z-50">
            <div className="flex justify-between items-center px-2 sm:px-4 py-0.5 sm:py-1 translate-y-1">
                {/* Logo */}
                <div className="flex items-center">
                    {/* Logo está ligeramente elevado */}
                    <img
                        src="/assets/logo.png"
                        alt="Loopin Logo"
                        className="absolute h-14 w-auto sm:h-16 md:h-20 lg:h-24 object-contain translate-y-2"
                    />
                </div>

                {/* Navigation Menu */}
                <nav className="flex flex-grow justify-center space-x-2 sm:space-x-4 md:space-x-6 lg:space-x-8 text-xs sm:text-sm md:text-base lg:text-lg font-semibold">
                    <Link
                        to="/"
                        className="hover:text-[#43be95] transition-colors duration-300 whitespace-nowrap"
                    >
                        Dashboard
                    </Link>
                    <Link
                        to="/operations"
                        className="hover:text-[#43be95] transition-colors duration-300 whitespace-nowrap"
                    >
                        Operations
                    </Link>
                    <Link
                        to="/history"
                        className="hover:text-[#43be95] transition-colors duration-300 whitespace-nowrap"
                    >
                        History
                    </Link>
                </nav>

                {/* User Info */}
                <div className="relative flex items-center space-x-4">
                    {user ? (
                        <div className="relative">
                            {/* User Image */}
                            <button
                                onClick={toggleDropdown}
                                className="focus:outline-none"
                            >
                                <img
                                    src={user.picture}
                                    alt="User Avatar"
                                    className="h-7 w-7 sm:h-8 sm:w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 rounded-full border-2 border-gray-400 hover:border-accent"
                                />
                            </button>

                            {/* Dropdown Menu */}
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-panelBackground shadow-lg rounded-md py-2 z-50">
                                    <button
                                        onClick={handleLogout}
                                        className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-700 hover:text-white"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link
                            to="/login"
                            className="text-xs sm:text-sm md:text-base hover:text-gray-400 whitespace-nowrap"
                        >
                            Login
                        </Link>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
