import React, { useState } from 'react';
import { openOrder, closeOrder, saveUserConfig, saveOrderConfig } from '../services/api';
import { useAuth } from '../hooks/useAuth';
import { FaUserCog, FaCogs, FaKey } from 'react-icons/fa';

const Operations = () => {
    const { user } = useAuth();

    const [activeTab, setActiveTab] = useState("Loopin Trader");
    const [symbols] = useState([
        "SUIUSDTM",
        "BTCUSDTM",
        "ETHUSDTM",
        "XLMUSDTM",
        "TRXUSDTM",
        "XRPUSDTM",
        "ADAUSDTM",
        "BNBUSDTM",
    ]);
    const [symbol, setSymbol] = useState("SUIUSDTM");
    const [leverage, setLeverage] = useState(10);
    const [quantity, setQuantity] = useState(50);
    const [stopLossPercent, setStopLossPercent] = useState(0.5);
    const [takeProfitPercent, setTakeProfitPercent] = useState(1.0);
    const [isUserConfigOpen, setIsUserConfigOpen] = useState(false);
    const [isOpsConfigOpen, setIsOpsConfigOpen] = useState(false);
    const [autoCloseEnabled, setAutoCloseEnabled] = useState(true);
    const [alias, setAlias] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [apiPassphrase, setApiPassphrase] = useState("");

    const handleTabClick = (tab) => setActiveTab(tab);

    const handleSaveUserConfig = async () => {
        const configData = {
            userId: user.id,
            alias,
            apiKey,
            apiSecret,
            apiPassphrase,
        };

        try {
            await saveUserConfig(configData);
            alert("Configuración de usuario guardada correctamente.");
            setIsUserConfigOpen(false);
        } catch (error) {
            console.error("Error al guardar la configuración de usuario:", error);
            alert("Hubo un error al guardar la configuración.");
        }
    };

    const handleSaveOrderConfig = async () => {
        const orderData = {
            userId: user.id,
            leverage: leverage,
            balanceUsage: quantity,
            symbol: symbol,
            currency: "USDT",
            stopLossPercent: stopLossPercent,
            takeProfitPercent: takeProfitPercent,
            autoCloseEnabled: autoCloseEnabled,
        };

        try {
            await saveOrderConfig(orderData);
            alert("Configuración de usuario guardada correctamente.");
        } catch (error) {
            console.error("Error al guardar la configuración de usuario:", error);
            alert("Hubo un error al guardar la configuración.");
        }
    };

    const handleSaveOpsConfig = () => {
        alert("Configuración de operaciones guardada correctamente.");
        setIsOpsConfigOpen(false);
    };

    const handleBuy = async () => {
        const orderData = {
            signal: "LONG",
            symbol: symbol,
            currency: "USDT",
            priceType: activeTab === "Loopin Trader" ? "limitPrice" : undefined,
            leverage: leverage,
            balanceUsage: quantity,
            stopLossPercent: stopLossPercent,
            takeProfitPercent: takeProfitPercent,
        };

        try {
            const response = await openOrder(orderData, user);
            alert(`Orden de compra abierta: ${JSON.stringify(response)}`);
        } catch (error) {
            console.error("Error al abrir la orden de compra:", error);
        }
    };

    const handleSell = async () => {
        const orderData = {
            signal: "SHORT",
            symbol: symbol,
            currency: "USDT",
            priceType: activeTab === "Loopin Trader" ? "limitPrice" : undefined,
            leverage: leverage,
            balanceUsage: quantity,
            stopLossPercent: stopLossPercent,
            takeProfitPercent: takeProfitPercent,
        };

        try {
            const response = await openOrder(orderData, user);
            alert(`Orden de venta abierta: ${JSON.stringify(response)}`);
        } catch (error) {
            console.error("Error al abrir la orden de venta:", error);
        }
    };

    const handleCloseOrder = async () => {
        try {
            const response = await closeOrder(user);
            alert(`Orden de venta abierta: ${JSON.stringify(response)}`);
        } catch (error) {
            console.error("Error al abrir la orden de venta:", error);
        }
    };

    return (
        <div className="flex flex-col gap-4 p-4">
            {/* Panel de Operaciones */}
            <div className="rounded-lg rounded-br-lg bg-panelBackground text-textLight p-4 w-full max-w-4xl mx-auto my-16 sm:w-12/12 shadow-lg">
                <div className="mb-4">
                    <label htmlFor="symbol" className="text-sm font-medium">Símbolo:</label>
                    <select
                        id="symbol"
                        value={symbol}
                        onChange={(e) => setSymbol(e.target.value)}
                        className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                    >
                        {symbols.map((sym) => (
                            <option key={sym} value={sym}>
                                {sym}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex justify-between border-b border-gray-600 pb-2">
                    {["Loopin Trader", "Loopin Auto Bot", "Stop límite"].map((tab) => (
                        <button
                            key={tab}
                            className={`tab-button px-4 py-2 text-sm ${
                                activeTab === tab
                                    ? "text-[#43be95] border-b-2 border-[#43be95] font-bold"
                                    : "hover:text-[#43be95]"
                            }`}
                            onClick={() => handleTabClick(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>

                <div className="operations-form mt-4">
                        <div>
                            <div className="form-group flex items-center gap-2">
                                <label className="text-sm font-medium">Apalancamiento:</label>
                                <input
                                    type="range"
                                    min="1"
                                    max="75"
                                    value={leverage}
                                    onChange={(e) => setLeverage(e.target.value)}
                                    className="flex-1 accent-[#43be95]"
                                />
                                <span className="text-sm font-medium">{leverage}x</span>
                            </div>
                            <div className="form-group flex items-center gap-2 mt-4">
                                <label className="text-sm font-medium">Cantidad:</label>
                                <input
                                    type="range"
                                    min="1"
                                    max="100"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    className="flex-1 accent-[#43be95]"
                                />
                                <span className="text-sm font-medium">{quantity}</span>
                            </div>
                        </div>
                </div>

                <div className="form-group flex justify-between gap-2 mt-4">
                    <div className="w-1/2">
                        <label className="text-sm font-medium">Stop Loss (%):</label>
                        <input
                            type="number"
                            className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                            value={stopLossPercent}
                            onChange={(e) => setStopLossPercent(e.target.value)}
                        />
                    </div>
                    <div className="w-1/2">
                        <label className="text-sm font-medium">Take Profit (%):</label>
                        <input
                            type="number"
                            className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                            value={takeProfitPercent}
                            onChange={(e) => setTakeProfitPercent(e.target.value)}
                        />
                    </div>
                </div>
                

                
                {activeTab === "Loopin Trader" && (
                    <div className="flex flex-wrap justify-between gap-2 mt-4">
                        <button
                            className="bg-[#43be95] text-white px-2 py-1 sm:px-4 sm:py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                            onClick={handleBuy}
                        >
                            Comprar/Largo
                        </button>
                        <button
                            className="bg-[#3b340d] text-white px-2 py-1 sm:px-4 sm:py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                            onClick={handleCloseOrder}
                        >
                            Cerrar
                        </button>
                        <button
                            className="bg-danger text-white px-2 py-1 sm:px-4 sm:py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                            onClick={handleSell}
                        >
                            Vender/Corto
                        </button>
                    </div>
                )}

                {activeTab === "Loopin Auto Bot" &&  (
                    <div>
                <div className="form-group flex items-center justify-center mt-4">
                    <div className="relative w-6 h-6">
                        <input
                            type="checkbox"
                            id="autoCloseEnabled"
                            checked={autoCloseEnabled}
                            onChange={(e) => setAutoCloseEnabled(e.target.checked)}
                            className="appearance-none w-full h-full border-2 border-gray-700 rounded-full bg-dark focus:outline-none focus:ring-2 focus:ring-[#43be95] checked:bg-[#43be95] transition-all cursor-pointer shadow-md"
                        />
                        
                    </div>
                    <label htmlFor="autocloseEnabled" className="text-sm font-medium ml-2 text-center">Activar cierre automático</label>
                </div>
                        <div className="flex justify-between gap-1 mt-4">
                            <button
                                className="bg-[#34362b] text-white px-4 py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                                onClick={handleSaveOrderConfig}
                            >
                                Guardar Parámetros
                            </button>
                        </div>
                    </div>
                )}

                {/* Botones Configuración */}
                <div className="secondary-buttons flex justify-around mt-4">
                    <button
                        className="bg-panelBackground px-4 py-2 rounded-lg text-white hover:bg-gray-700 text-sm"
                        onClick={() => setIsUserConfigOpen(true)}
                    >
                        <FaUserCog size={20} />
                    </button>
                    <button
                        className="bg-panelBackground px-4 py-2 rounded-lg text-white hover:bg-gray-700 text-sm"
                        onClick={() => setIsOpsConfigOpen(true)}
                    >
                        <FaCogs size={20} />
                    </button>
                </div>

                {/* Popup Configuración Usuario */}
                {isUserConfigOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-panelBackground text-textLight p-6 rounded-lg w-full max-w-lg">
                            <h2 className="text-lg font-semibold mb-4">Configuración del Usuario</h2>
                            <div className="form-group mb-4">
                                <label className="text-sm font-medium">Alias:</label>
                                <input
                                    type="text"
                                    className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight"
                                    value={alias}
                                    onChange={(e) => setAlias(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label className="text-sm font-medium">API Key:</label>
                                <input
                                    type="text"
                                    className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight"
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label className="text-sm font-medium">API Secret:</label>
                                <input
                                    type="text"
                                    className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight"
                                    value={apiSecret}
                                    onChange={(e) => setApiSecret(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label className="text-sm font-medium">API Passphrase:</label>
                                <input
                                    type="text"
                                    className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight"
                                    value={apiPassphrase}
                                    onChange={(e) => setApiPassphrase(e.target.value)}
                                />
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="px-4 py-2 rounded-lg bg-[#43be95] text-white"
                                    onClick={handleSaveUserConfig}
                                >
                                    Guardar
                                </button>
                                <button
                                    className="px-4 py-2 rounded-lg bg-danger text-white"
                                    onClick={() => setIsUserConfigOpen(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Popup Configuración Operaciones */}
                {isOpsConfigOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-panelBackground text-textLight p-6 rounded-lg w-full max-w-lg">
                            <h2 className="text-lg font-semibold mb-4">Configuración de Operaciones</h2>
                            <p className="text-sm mb-4">
                                Para enlazar con TradingView, sigue estos pasos:
                            </p>
                            <ol className="list-decimal list-inside text-sm mb-4">
                                <li>Accede a tu cuenta de TradingView.</li>
                                <li>Genera una clave API desde la sección de integraciones.</li>
                                <li>Ingresa la clave API en el campo correspondiente de configuración.</li>
                            </ol>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="px-4 py-2 rounded-lg bg-[#43be95] text-white"
                                    onClick={handleSaveOpsConfig}
                                >
                                    Guardar
                                </button>
                                <button
                                    className="px-4 py-2 rounded-lg bg-danger text-white"
                                    onClick={() => setIsOpsConfigOpen(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Tabla de Resultados */}
            <div className="flex justify-center w-full">
                <div
                    className="bg-panelBackground text-white rounded-lg p-4 mt-2 w-full shadow-lg"
                    style={{
                        height: 'calc(100vh - 40rem)', // Ajusta este valor para calcular la altura precisa desde el panel superior
                        overflowY: 'scroll', // Permite el scroll vertical
                        scrollbarWidth: 'none', // Oculta la barra de scroll en navegadores compatibles (Firefox)
                        msOverflowStyle: 'none', // Oculta la barra de scroll en Internet Explorer/Edge
                    }}
                >
                    <h3 className="text-lg font-semibold mb-4 text-center">Historial de Operaciones</h3>
                    <div className="overflow-hidden h-full">
                        <table className="w-full text-xs sm:text-sm border-collapse border border-gray-700 table-fixed">
                            <thead className="bg-gray-700 text-white">
                                <tr>
                                    <th className="py-1 px-1 sm:px-2 border border-gray-600 truncate">Contrato</th>
                                    <th className="py-1 px-1 sm:px-2 border border-gray-600 truncate">Tipo</th>
                                    <th className="py-1 px-1 sm:px-2 border border-gray-600 truncate">Pérdidas/Ganancias</th>
                                    <th className="py-1 px-1 sm:px-2 border border-gray-600 truncate">Hora</th>
                                    <th className="py-1 px-1 sm:px-2 border border-gray-600 truncate">Detalles</th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-800">
                                <tr>
                                    <td className="py-1 px-1 sm:px-2 border border-gray-700 truncate">SUI/USDT</td>
                                    <td className="py-1 px-1 sm:px-2 text-red-500 border border-gray-700 truncate">Corto</td>
                                    <td className="py-1 px-1 sm:px-2 border border-gray-700 truncate">-0.01 USDT</td>
                                    <td className="py-1 px-1 sm:px-2 border border-gray-700 truncate">2024/11/25</td>
                                    <td className="py-1 px-1 sm:px-2 border border-gray-700 truncate">
                                        <button className="text-blue-400 hover:underline">Ver</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Operations;
