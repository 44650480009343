import axios from 'axios';
import { useAuth } from '../hooks/useAuth';

const API_BASE_URL = 'https://c1cb-178-62-57-59.ngrok-free.app'; // Cambia si el backend tiene otro puerto o dominio
//const API_BASE_URL = 'http://localhost:8080';

export const openOrder = async (orderData, user) => {
    if (!user) {
        throw new Error('Usuario no autenticado');
    }
    
    try {
        const response = await axios.post(`${API_BASE_URL}/webhook/open`, {
            ...orderData,
            userId: user.id, // Incluye el userId en el payload
        });
        return response.data;
    } catch (error) {
        console.error('Error opening order:', error);
        throw error;
    }
};

export const closeOrder = async (user) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/webhook/close`, {
            userId: user.id, // Incluye el userId en el payload
        });
        return response.data;
    } catch (error) {
        console.error('Error saving user config:', error);
        throw error;
    }
};

export const saveUserConfig = async (configData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/user-configs/update`, configData);
        return response.data;
    } catch (error) {
        console.error('Error saving user config:', error);
        throw error;
    }
};


export const saveOrderConfig = async (orderData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/trading-configs/update`, orderData);
        return response.data;
    } catch (error) {
        console.error('Error saving user config:', error);
        throw error;
    }
};