import React from 'react';
import { useAuth } from '../hooks/useAuth';

const Dashboard = () => {
    const { user } = useAuth();

    return (
        <div className="dashboard-container">
            <header className="dashboard-header">
                <h1>Welcome, {user?.name || '!'}</h1>
                <p>Manage your operations and track your market trends.</p>
            </header>
            <section className="dashboard-content">
                <h2>Latest Activity</h2>
                <p>Here you can see the latest trades and updates.</p>
            </section>
        </div>
    );
};

export default Dashboard;