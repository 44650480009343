import React from 'react';
import './styles/History.css';

const History = () => {
    return (
        <div className="history-container">
            <h1>Trade History</h1>
            <div className="history-table">
                <table>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>12345</td>
                            <td>BUY</td>
                            <td>$45,000</td>
                            <td>2024-11-26</td>
                        </tr>
                        <tr>
                            <td>67890</td>
                            <td>SELL</td>
                            <td>$50,000</td>
                            <td>2024-11-27</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default History;