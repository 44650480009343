import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import Dashboard from './components/Dashboard';
import Operations from './components/Operations';
import History from './components/History';
import Login from './components/Login';
import Header from './components/Header';
import './styles/global.css';
import './styles/output.css';

const App = () => {
    return (
        <AuthProvider>
            <div>
                <Router>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/*"
                            element={
                                <>
                                    <Header />
                                    <div className="">
                                        <Routes>
                                            <Route path="/" element={<Dashboard />} />
                                            <Route path="/operations" element={<Operations />} />
                                            <Route path="/history" element={<History />} />
                                        </Routes>
                                    </div>
                                </>
                            }
                        />
                    </Routes>
                </Router>
            </div>
        </AuthProvider>
    );
};

export default App;
