import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    //const API_BASE_URL = 'http://localhost:8080';
    const API_BASE_URL = 'https://c1cb-178-62-57-59.ngrok-free.app';
    const handleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                // Obtener información del usuario desde Google
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                });

                const userInfo = await userInfoResponse.json();

                const user = {
                    id: userInfo.id || userInfo.sub, // Google ID
                    name: userInfo.name,
                    email: userInfo.email,
                    picture: userInfo.picture,
                    token: tokenResponse.access_token,
                };

                // Registrar al usuario en el backend usando /api/auth/login
                const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
                    userId: user.id,
                    email: user.email,
                });

                // Guardar el token y el usuario en el contexto
                user.jwt = response.data.token; // Agregar el token JWT al usuario
                login(user);

                // Redirigir al dashboard
                navigate('/');
            } catch (error) {
                console.error('Error durante el login o registro:', error);
            }
        },
        onError: (error) => {
            console.error('Error durante Google login:', error);
        },
    });

    return (
        <div className="login-container">
            <div className="login-card">
                <h1>Welcome to Loopin</h1>
                <button onClick={() => handleLogin()}>Sign in with Google</button>
            </div>
        </div>
    );
};

export default Login;